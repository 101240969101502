import React from "react"
import Button from "../components/Button"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div style={{marginTop:'2.5rem', display:"flex", flexDirection:"column"}}>
      <h1>Welcome, members!</h1>
      <Button to="/events/">See all events</Button>
      <br/>
      <Button to="https://www.notion.so/ecmembers/Embarc-Collective-Information-Hub-9f275036b3ea42beb384592fbb909d2c" external>Member Notion</Button>
    </div>
  </Layout>
)

export default IndexPage
